const ACCESS_TOKEN_KEY = "access_token" as string;
const REFRESH_TOKEN_KEY = "refresh_token" as string;

export const getAccessToken = (): string | null => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = (): string | null => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const saveTokens = (accessToken, refreshToken: string): void => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const destroyTokens = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export default { getAccessToken, getRefreshToken, saveTokens, destroyTokens };
