import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ExActions, ExMutations } from "@/exstore/ExStoreEnums";
import ExApiService from "@/excore/ExApiService";
import { ErrorPopup } from "@/excore/Popup";
import ExJwtService from "@/excore/ExJwtService";

export interface User {
  name: string;
  surname: string;
  email: string;
}

export interface ExUserAuthInfo {
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class ExAuthModule extends VuexModule implements ExUserAuthInfo {
  user = {} as User;
  isAuthenticated = !!ExJwtService.getAccessToken();

  get currentUser(): User {
    return this.user;
  }

  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  @Mutation
  [ExMutations.SET_AUTH](isAuthenticated) {
    this.isAuthenticated = isAuthenticated;
  }

  @Mutation
  [ExMutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [ExMutations.LOGOUT]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    ExJwtService.destroyTokens();
    const redirect = window.location.pathname + window.location.search;
    const urlParams = new URLSearchParams();
    urlParams.set("redirect", redirect);
    document.location.href = "/#/sign-in?" + urlParams.toString();
  }

  @Action
  async [ExActions.LOGIN]({ access_token, refresh_token }) {
    ExJwtService.saveTokens(access_token, refresh_token);

    this.context.commit(ExMutations.SET_AUTH, true);
    await this.context.dispatch(ExActions.UPDATE_USER);
  }

  @Action
  [ExActions.LOGOUT]() {
    this.context.commit(ExMutations.LOGOUT);
  }
  @Action
  async [ExActions.UPDATE_USER]() {
    if (this.context.getters.isUserAuthenticated !== true) {
      return;
    }

    const [error, data] = await ExApiService.get("user/me");
    if (error) {
      ErrorPopup(error);
      return;
    }
    this.context.commit(ExMutations.SET_USER, data);
  }
}
