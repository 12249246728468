import axios, { AxiosInstance } from "axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store/";
import ExJwtService from "@/excore/ExJwtService";
import { ExActions } from "@/exstore/ExStoreEnums";
import { IApiQuery } from "@/exmodels/ApiQuery";

class ExApiService {
  private static baseUrl = process.env.VUE_APP_API_URL;
  private static instance: AxiosInstance;
  private static isRefreshing = false;
  private static failedQueue = Array<Promise<unknown>>();
  private static init() {
    ExApiService.instance = axios.create({
      baseURL: ExApiService.baseUrl,
    });
    ExApiService.instance.defaults.headers.common["Accept"] = "application/json";
    ExApiService.instance.interceptors.request.use(
      (req) => {
        const token = ExJwtService.getAccessToken();
        if (token) req.headers.common["Authorization"] = `Bearer ${token}`;
        return req;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
    ExApiService.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  private static async refresh<T>(error) {
    const originalRequest = error.config;
    if (originalRequest.url.startsWith("/auth")) {
      return Promise.reject(error);
    }

    const refreshToken = ExJwtService.getRefreshToken();
    if (!refreshToken) {
      await store.dispatch(ExActions.LOGOUT);
      return;
    }
    if (originalRequest._retry) {
      return Promise.reject(error);
    }
    if (this.isRefreshing) {
      return new Promise(function (resolve, reject) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ExApiService.failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    originalRequest._retry = true;
    this.isRefreshing = true;
    return new Promise(function (resolve, reject) {
      axios
        .post(`${ExApiService.baseUrl}/auth/refresh`, { refresh_token: refreshToken })
        .then(({ data }) => {
          ExJwtService.saveTokens(data.data.access_token, data.data.refresh_token);
          originalRequest.headers["Authorization"] = "Bearer " + data.data.access_token;
          ExApiService.processQueue(null, data.data.access_token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          ExApiService.processQueue(err, null);
          store.dispatch(ExActions.LOGOUT).finally(() => reject(err));
        })
        .finally(() => {
          ExApiService.isRefreshing = false;
        });
    });
  }

  private static processQueue(error, token = null) {
    this.failedQueue.forEach((prom) => {
      if (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        prom.reject(error);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        prom.resolve(token);
      }
    });

    this.failedQueue = [];
  }

  private static async request<T>(
    config: AxiosRequestConfig
  ): Promise<[null, ApiResponse<T>] | [string, ApiResponse<T>]> {
    if (!ExApiService.instance) {
      this.init();
    }
    try {
      const { data } = await ExApiService.instance.request<ApiResponse<T>>(config);
      return [null, data];
    } catch (error: any) {
      const t: ApiResponse<T> = <ApiResponse<T>>{};
      if (!error.response) {
        return ["Bağlantı Hatası", t];
      }

      if (error?.response?.status === 401 && error?.request?.responseURL.includes("login") === false) {
        if (!ExJwtService.getAccessToken()) {
          // edge case. refresh token süresi dolduktan sonra sayfa yenilenirse ilk istekte yenileme fail oluyor.
          // yenileme fail olduktan sonra ikinci istek yapılıyor ama refreshin fail olduğunun farkında değil.
          // - dönüp, popup açarken kontrol ediyoruz. şimdilik daha iyi bi çözüm gelmedi aklıma.
          // TODO: daha iyi bi çözüm bul.
          return ["-", t];
        }
        try {
          const data = await this.refresh(error);
          // yeniden deneme yapınca axios istance olarak yapıyor, içinden datayı alıp data dönememiz gerekiyor.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return [null, data.data as ApiResponse<T>];
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (e.response?.status === 401) {
            // refresh token yenilendi ama request fail etti durumu
            return ["Oturum süreniz dolmuştur", t];
          }
        }
      }

      if (error?.response?.status === 403) {
        return ["Yetki Hatası", t];
      }

      if (error?.response?.status === 500) {
        return ["Sunucu içi hata", t];
      }

      const msg = error?.response?.data?.error_message || "Bilinmeyen Hata";

      return [msg, t];
    }
  }

  public static async get<T>(route: string, query?: IApiQuery, urlParams?: URLSearchParams) {
    const searchParams = new URLSearchParams();
    if (query) {
      searchParams.set("page", query.page.toString());
      searchParams.set("per_page", query.perPage.toString());
      for (let i = 0; i < query.columns.length; i++) {
        searchParams.append("columns", query.columns[i]);
        searchParams.append("column_types", query.columnTypes[i].toString());
        searchParams.append("query", query.query[i]);
      }
      for (let i = 0; i < query.sortColumns.length; i++) {
        searchParams.append("sort_columns", query.sortColumns[i]);
        searchParams.append("sort_column_types", query.sortColumnTypes[i].toString());
        searchParams.append("sort_orders", query.sortOrders[i]);
      }
    }
    return this.request<T>({
      method: "get",
      url: `/${route}`,
      params: searchParams,
    });
  }

  public static async post<T>(route: string, data?: unknown, urlParams?: unknown, headers?: unknown) {
    return this.request<T>({
      method: "post",
      url: `/${route}`,
      data,
      params: urlParams,
      headers: headers,
    });
  }

  public static put<T>(route: string, data?: unknown, urlParams?: unknown) {
    return this.request<T>({
      method: "put",
      url: `/${route}`,
      data,
      params: urlParams,
    });
  }

  public static delete<T>(route: string, urlParams?: unknown) {
    return this.request<T>({
      method: "delete",
      url: `/${route}`,
      params: urlParams,
    });
  }
}

export default ExApiService;
