import Swal from "sweetalert2";

export function ErrorPopup(text: string) {
  if (text === "-") {
    return;
  }
  return Swal.fire({
    html: text,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: "Tamam",
    customClass: {
      confirmButton: "btn btn-primary",
    },
  });
}

export function SuccessPopup(text: string) {
  return Swal.fire({
    html: text,
    icon: "success",
    buttonsStyling: false,
    confirmButtonText: "Tamam",
    customClass: {
      confirmButton: "btn btn-primary",
    },
  });
}

export function WarningPopup(html: string, confimText: string, cancelText: string) {
  return Swal.fire({
    title: "Dikkat",
    html: html,
    icon: "warning",
    showCancelButton: true,
    // confirmButtonColor: "#3085d6",
    // cancelButtonColor: "#cc3636",
    confirmButtonText: confimText,
    cancelButtonText: cancelText,
  });
}

export async function ConfirmPopup(text: string, confimText: string, cancelText: string) {
  const result = await Swal.fire({
    title: text,
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonText: confimText,
    cancelButtonText: cancelText,
  });
  return result.isConfirmed;
}
