enum ExActions {
  LOGIN = "login",
  LOGOUT = "logout",
  UPDATE_USER = "updateUser",
}

enum ExMutations {
  LOGOUT = "logOut",
  SET_USER = "setUser",
  SET_AUTH = "setAuth",
}

export { ExActions, ExMutations };
